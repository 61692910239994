import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["select"];
  static values = { options: Array };

  connect() {
    new TomSelect(this.selectTarget, {
      valueField: "id",
      searchField: ["title", "tags"],
      maxItems: 1,
      options: this.optionsValue,
      maxOptions: 100,
      load: function (query, callback) {
        var url = "/icons?account_id=62&q=" + encodeURIComponent(query);
        fetch(url)
          .then((response) => response.json())
          .then((json) => {
            callback(json.data);
          })
          .catch(() => {
            callback();
          });
      },
      render: {
        option: function (data, escape) {
          return (
            '<div class="flex flex-col">' +
            '<div class="flex">' +
            '<img class="image w-10 h-10 mr-2 bg-gray-200 rounded-sm" src=' +
            escape(data.icon_url) +
            ">" +
            '<span class="title">' +
            escape(data.title) +
            "</span>" +
            "</div>" +
            "</div>"
          );
        },
        item: function (data, escape) {
          return (
            '<div><div class="flex flex-row">' +
            '<img class="image w-4 h-4 mr-2" src=' +
            escape(data.icon_url) +
            ">" +
            '<span class="title">' +
            escape(data.title) +
            "</span>" +
            "</div></div>"
          );
        },
      },
    });
  }
}
